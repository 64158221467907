.BusinessViewEachCon1 {
  scroll-snap-type: y mandatory;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: inline-flex;
  overflow: hidden scroll;
}

.BusinessViewEachCon1 > * {
  scroll-snap-align: start;
}

.BusinessViewEachUserActionsCon1 {
  background: linear-gradient(0deg, #3a7d44 0% 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px 12px 6px;
  display: flex;
}

.BusinessViewEachUserActionsCon2 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  display: flex;
}

.BusinessViewEachUserActionsCon3 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.BusinessViewEachUserActionsCon4 {
  color: #000;
  word-wrap: break-word;
  background: #00e617;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessViewEachUserActionsCon4B {
  color: #000;
  word-wrap: break-word;
  background: #6fff82;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessViewEachUserActionsCon5 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.BusinessViewEachUserActionsCon6 {
  background: #fff;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 44px;
  padding: 6px 12px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessViewEachUserActionsCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.BusinessViewEachUserActionsCon8 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.BusinessViewEachUserActionsCon9 {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachUserActionsCon9Text {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.BusinessViewEachCon2 {
  background: var(--app-beige);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px;
  display: flex;
}

.BusinessViewEachCon3 {
  max-width: 300px;
  box-shadow: var(--app-shadow-special-anouncements);
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  display: flex;
}

.BusinessViewEachCon4 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon4Text {
  text-align: center;
  color: #3a7d44;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.BusinessViewEachCon4Text2 {
  text-align: center;
  color: #ff7043;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.BusinessViewEachCon5 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon5Text {
  text-align: center;
  color: #6d6d6d;
  word-wrap: break-word;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

.BusinessViewEachCon6 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: inline-flex;
}

.BusinessViewEachCon7 {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon7Text {
  color: #2b2b2b;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.BusinessViewEachCon8 {
  color: #fff;
  word-wrap: break-word;
  background: #3a7d44;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessViewEachCon9 {
  background: var(--app-beige);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 12px 0 32px;
  display: flex;
}

.BusinessViewEachCon10 {
  align-self: stretch;
}

.BusinessViewEachCon11 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.Frame213 {
  background: #fff;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  display: inline-flex;
}

.MasVaAqui {
  color: #2b2b2b;
  letter-spacing: .2px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
}

.BusinessViewEachHeaderCon1 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.BusinessViewEachHeaderCon2 {
  z-index: 0;
  background: linear-gradient(179deg, #0000 0%, #000c 100%), linear-gradient(#000000b3 0%, #0000 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.BusinessViewEachHeaderCon3 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.BusinessViewEachHeaderCon4 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding-right: 12px;
  display: inline-flex;
}

.BusinessViewEachHeaderCon5 {
  background: linear-gradient(270deg, #000 0%, #0000 100%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 4px 6px;
  display: flex;
}

.Regresar {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.BusinessViewEachHeaderCon6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  display: inline-flex;
}

.Verificado {
  text-align: center;
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 6px;
  font-weight: 400;
}

.BusinessViewEachHeaderCon7 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 6px 24px;
  display: flex;
}

.BusinessViewEachHeaderCon8 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachHeaderCon9 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.BusinessViewEachHeaderCon10 {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  padding: 6px 12px;
  display: inline-flex;
}

.BusinessViewEachHeaderCon11 {
  border: .5px solid #fff;
  border-radius: 100px;
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.BusinessViewEachHeaderCon11Img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 12px #fff, 0 0 12px #000;
}

.BusinessViewEachHeaderCon12 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  height: 75px;
  display: flex;
}

.BusinessViewEachHeaderCon13 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.RoundButton {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.Frame64 {
  background: #000000b3;
  border-radius: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  display: flex;
  box-shadow: 0 2px 4px 1px #ffffff80;
}

.Label {
  text-align: center;
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
}

.BusinessViewEachHeaderCon14 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  height: 15px;
  display: flex;
}

.Ratings {
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  display: inline-flex;
}

.RatingStarCon2 {
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
}

.RatingStarRatedAtNumber {
  text-align: center;
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
}

.RatingStarCon3 {
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  display: flex;
}

.RatingStarReviewText {
  text-align: center;
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.shrink-and-remove {
  animation: .5s forwards shrinkRight;
}

@keyframes shrinkRight {
  0% {
    opacity: 1;
    transform: scale(1)translateX(0);
  }

  100% {
    opacity: .6;
    transform: scale(.5)translateX(150vw);
  }
}

.addForShareText {
  color: #fff;
  text-align: center;
  letter-spacing: .4px;
  background: linear-gradient(90deg, #3a7d44 25.52%, #f4c542 35.49%);
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
}

.addForShareText span {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .4px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessEachSearchCon1 {
  box-sizing: border-box;
  background: linear-gradient(#3a7d44 5%, #3a7d441a 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 12px;
  display: flex;
}

.BusinessEachSearchCon2 {
  width: 100%;
  max-width: 500px;
  box-shadow: var(--app-in-business-search);
  background: #fff;
  border: .5px solid #6d6d6d;
  border-radius: 10px;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  display: inline-flex;
}

.BusinessEachSearchInput {
  color: #6d6d6d;
  word-wrap: break-word;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
}

.BusinessEachSearchCon3 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessEachSearchCon4ResultsCon {
  background: #fff;
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  max-height: 70vh;
  padding: 12px;
  display: flex;
  overflow: hidden scroll;
}

.BusinessEachSearchCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: none;
}

.BusinessEachSearchCon4Active {
  display: flex;
}

.BusinessEachSearchCon5 {
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
}

.BusinessEachSearchCon6 {
  border: .5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  border-radius: 5px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  display: none;
}

.BusinessEachSearchCon6Active {
  display: flex;
}
/*# sourceMappingURL=index.974c621c.css.map */
