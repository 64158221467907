.BusinessViewEachCon1 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
.BusinessViewEachCon1 > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}

.BusinessViewEachUserActionsCon1 {
  align-self: stretch;
  padding-top: 12px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(0deg, #3a7d44 0%, #3a7d44 100%);
  flex-direction: column;
  justify-content: flex-start; /* Keeps vertical alignment intact */
  align-items: center; /* Centers child elements horizontally */
  gap: 12px;
  display: flex;
}

.BusinessViewEachUserActionsCon2 {
  width: 100%;
  max-width: 500px;
  /* align-self: stretch; */
  justify-content: space-between; /* Maintains spacing between inner items */
  align-items: center; /* Centers inner items vertically */
  display: flex;
}

.BusinessViewEachUserActionsCon3 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.BusinessViewEachUserActionsCon4 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: relative;

  border-radius: 8px;
  background: #00e617;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: black;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}
.BusinessViewEachUserActionsCon4B {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  background: #6fff82;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: black;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachUserActionsCon5 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.BusinessViewEachUserActionsCon6 {
  width: 44px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.BusinessViewEachUserActionsCon7 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.BusinessViewEachUserActionsCon8 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.BusinessViewEachUserActionsCon9 {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachUserActionsCon9Text {
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon2 {
  align-self: stretch;
  padding: 12px;
  flex-direction: column;
  justify-content: flex-start; /* Optional, for vertical alignment */
  align-items: center; /* Centers child elements horizontally */
  gap: 12px;
  display: flex;
  background: var(--app-beige);
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25) inset; */
}

.BusinessViewEachCon3 {
  align-self: center; /* Optional for extra safety, ensures it stays centered */
  max-width: 300px;
  padding: 12px 24px;
  background: white;
  border-radius: 12px;
  /* border: 1px black solid; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
  box-shadow: var(--app-shadow-special-anouncements);
}

.BusinessViewEachCon4 {
  align-self: stretch;
  /* height: 45px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon4Text {
  align-self: stretch;
  text-align: center;
  color: #3a7d44;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon4Text2 {
  align-self: stretch;
  text-align: center;
  color: #ff7043;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon5 {
  align-self: stretch;
  /* height: 40px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon5Text {
  align-self: stretch;
  text-align: center;
  color: #6d6d6d;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon6 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
}

.BusinessViewEachCon7 {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachCon7Text {
  color: #2b2b2b;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon8 {
  /* width: 47px; */
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #3a7d44;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;

  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachCon9 {
  /* align-self: stretch;
  padding: 12px;
  gap: 12px;
  */
  background: var(--app-beige);
  display: flex;
  padding: 12px 0px 32px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.BusinessViewEachCon10 {
  align-self: stretch;
  /* height: 572px; */
}

.BusinessViewEachCon11 {
  align-self: stretch;
  /* height: 36px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.Frame213 {
  align-self: stretch;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: white;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.MasVaAqui {
  color: #2b2b2b;
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}

/* Start Business header */
.BusinessViewEachHeaderCon1 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.BusinessViewEachHeaderCon2 {
  align-self: stretch;
  /* height: 315px; */
  background: linear-gradient(
      179deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  z-index: 0;
}

.BusinessViewEachHeaderCon3 {
  align-self: stretch;
  /* height: 315px; */
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.BusinessViewEachHeaderCon4 {
  align-self: stretch;
  padding-right: 12px;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.BusinessViewEachHeaderCon5 {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: linear-gradient(270deg, black 0%, rgba(0, 0, 0, 0) 100%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.Regresar {
  color: white;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessViewEachHeaderCon6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  display: inline-flex;
}

.Verificado {
  text-align: center;
  color: white;
  font-size: 6px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessViewEachHeaderCon7 {
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.BusinessViewEachHeaderCon8 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessViewEachHeaderCon9 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.BusinessViewEachHeaderCon10 {
  flex: 1 1 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  display: inline-flex;
}
.BusinessViewEachHeaderCon11 {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  border: 0.5px #fff solid;
  overflow: hidden;
}
.BusinessViewEachHeaderCon11Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 12px 0px #fff inset, 0px 0px 12px 0px #000;
}

.BusinessViewEachHeaderCon12 {
  align-self: stretch;
  height: 75px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessViewEachHeaderCon13 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.RoundButton {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.Frame64 {
  padding: 8px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 2px 4px 1px rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.Label {
  text-align: center;
  color: white;
  font-size: 10px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessViewEachHeaderCon14 {
  align-self: stretch;
  height: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.Ratings {
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  display: inline-flex;
}

.RatingStarCon2 {
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
}

.RatingStarRatedAtNumber {
  text-align: center;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

.RatingStarCon3 {
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  display: flex;
}

.RatingStarReviewText {
  text-align: center;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

/* Start Stack screens hide  */
.shrink-and-remove {
  animation: shrinkRight 0.5s forwards; /* Adjust duration as needed */
}

@keyframes shrinkRight {
  0% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) translateX(150vw); /* Move completely to the right */
    opacity: 0.6;
  }
}

/*  */

.addForShareText {
  display: none;
  padding: 2px 4px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border-radius: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, #3a7d44 25.52%, #f4c542 35.49%);

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.addForShareText span {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}

/* Start in business search */
.BusinessEachSearchCon1 {
  width: 100%;
  padding: 12px;
  background: linear-gradient(180deg, #3a7d44 5%, rgba(58, 125, 68, 0.1) 100%);
  flex-direction: column;
  justify-content: flex-start; /* Keeps vertical alignment */
  align-items: center; /* Centers child elements horizontally */
  gap: 12px;
  display: flex; /* Ensure it's a flex container */
  box-sizing: border-box;
}

.BusinessEachSearchCon2 {
  max-width: 500px;
  width: 100%;
  align-self: stretch;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  border-radius: 10px;
  border: 0.5px #6d6d6d solid;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  box-shadow: var(--app-in-business-search);
}

.BusinessEachSearchInput {
  flex: 1 1 0;
  color: #6d6d6d;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessEachSearchCon3 {
  align-self: stretch;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessEachSearchCon4ResultsCon {
  display: flex;
  max-height: 70vh;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  overflow-y: scroll;
}

.BusinessEachSearchCon4 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.BusinessEachSearchCon4Active {
  display: flex;
}

.BusinessEachSearchCon5 {
  display: flex;
  width: 100%; /* Makes it responsive */
  max-width: 500px; /* Limits the width */
  align-items: flex-start; /* Aligns inner content */
  gap: 12px;
  margin: 0 auto; /* Centers horizontally */
}

.BusinessEachSearchCon6 {
  display: none;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 0.5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
}
.BusinessEachSearchCon6Active {
  display: flex;
}
